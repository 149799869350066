import { Directive, ElementRef, EmbeddedViewRef, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DirectionTooltip } from '../models/tooltip.model';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {

  @Input() tooltip: TemplateRef<HTMLDivElement>;
  @Input() position = DirectionTooltip.TOPLEFT;
  @Input() classTooltip: string = '';
  private componentRef: EmbeddedViewRef<any>;

  constructor(
    private elementRef: ElementRef,
    private vcr: ViewContainerRef,
  ) { }

  @HostListener('mouseenter') showTooltip () {
    this.componentRef = this.vcr.createEmbeddedView(this.tooltip);
    this.componentRef.detectChanges()
    const domElem = this.componentRef.rootNodes[0] as HTMLElement;
    const { left, bottom, right } = this.elementRef.nativeElement.getBoundingClientRect();
    const { width } = domElem.getBoundingClientRect();
    console.log(this.elementRef.nativeElement.getBoundingClientRect(), domElem.getBoundingClientRect())
    if (this.classTooltip) {
      domElem.classList.add(this.classTooltip)
    }
    if (this.position === DirectionTooltip.BOTTOMLEFT) {
      domElem.classList.add(DirectionTooltip.BOTTOMLEFT)
      domElem.style.left = (left + 12) + 'px';
      domElem.style.top = (bottom - 8) + 'px';
    }
    if (this.position === DirectionTooltip.BOTTOMRIGHT) {
      domElem.classList.add(DirectionTooltip.BOTTOMRIGHT)
      domElem.style.left = (left - 229) + 'px';
      domElem.style.top = (bottom - 8) + 'px';
    }
    if (this.position === DirectionTooltip.TOPLEFT) {
      domElem.classList.add(DirectionTooltip.TOPLEFT)
      domElem.style.left = (left + 7) + 'px';
      domElem.style.top = (bottom - domElem.offsetHeight - 7) + 'px';
      domElem.style.background = `radial-gradient(1px 1px at 0% ${domElem.offsetHeight - 3}px, rgba(0, 0, 0, 0) 8px, ${this.classTooltip === 'project-theme'? '#FFFEDF' : '#FFF7F0'} 8px)`
    }
    document.body.appendChild(domElem);
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.componentRef.destroy();
  }

}
